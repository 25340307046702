@keyframes wave {
    0% {
        width: 8px;
        height: 8px;
        border-width: 4px;
        opacity: 1;
    }
    100% {
        width: 60px;
        height: 60px;
        border-width: 1px;
        opacity: 0;
    }
}

.wave1, .wave2, .wave3, .wave4 {
    animation-name: wave;
    animation-timing-function: ease-out;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.wave2 {
    animation-delay: 0.5s;
}

.wave3 {
    animation-delay: 1s;
}

.wave4 {
    animation-delay: 1.5s;
}

